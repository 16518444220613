<template>
  <div class="w100p">
    <div v-if="!emailSent" class="w100p">
      <h1 class="text-left f2-5 ma0 f1-5-mb mt2-mb">Forgot Password?</h1>
      <p class="font-axiroma-book text-left mb4 text-grey f1-3 mt0  mb1-mb text-grey">
        Please enter your registered email address to reset your password.
      </p>
      <div class="br0-5 border-grey">
        <div class="display-flex w100p" :class="{ 'bg-grey br0-5 ': isValidEmail }">
          <bh-icon icon="envelope" class="text-grey inline f1-5 pa2 pa1-mb" />
          <bh-input
            class="w100p"
            type="email"
            placeholder="Email"
            :value="user.email"
            @output="updateValue(user, 'email', $event)"
            required
            />
          <bh-icon
            class="inline f2 pa2 pb1 mt0--5 pa1-mb"
            :class="isValidEmail ? 'text-success': 'text-danger'"
            :icon="isValidEmail ? 'check-mark' : 'exclamation'"
            />
        </div>
      </div>
      <div class="mt5 display-flex justify-between">
        <router-link to="/login" class="pa1 w20 br0-5 f1-6 text-violet border-violet box-shadow-light">
          Back to login
        </router-link>
        <bh-button @output="submit" class="pa1 w20 br0-5 f1-6 bg-violet text-white border-violet box-shadow-light" :disabled="!isValidEmail">
          <bh-loader v-if="saving" :size="20" :width="2" color="#ffffff" />
          <span v-else>Submit</span>
        </bh-button>
      </div>
    </div>
    <div v-else>
      <div class="display-flex flex-column justify-center align-center">
        <bh-icon icon="check-mark" class="text-success inline f2-5 pa2 pb1 mt0--5 pa1-mb" />
        <span class="f2">Successfully Sent</span>
        <span class="text-grey f1-3 mt1">
          A link has been sent to your registered email address. Please go to the email and click
          on the link to reset your password.
        </span>
        <router-link to="/login" class="pa1 br0-5 f1-6 bg-violet text-white border-violet box-shadow-light w25p text-center mt2">
          Ok
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { User } from '@/services/user'
import { validateEmail, updateValue } from '@/utils'

export default {
  name: 'tbh-forgot-password',
  data() {
    return {
      emailSent: false,
      saving: false,
      user: {
        email: ''
      }
    }
  },
  computed: {
    isValidEmail() {
      return validateEmail(this.user.email)
    }
  },
  methods: {
    ...mapActions(['setSingleProperty']),
    updateValue,

    /**
     * @method submit
     * prevents page reload on submit
     */
    async submit() {
      if (!this.isValidEmail) return
      try {
        this.saving = true
        await User.forgotPassword({ email: this.user.email })
        this.saving = false
        this.emailSent = true
      } catch (err) {
        this.setSingleProperty(['toast', {
          show: true,
          duration: 3000,
          message: err.message || err?.error?.message || 'Uh oh... that shouldn\'t have happened, Please try again.',
          color: '#F44336'
        }])
        this.saving = false
      }
    }
  }
}
</script>
